import type { ParentProps } from "solid-js";
import { Show, Suspense, createResource, splitProps } from "solid-js";
import server$ from "solid-start/server";

import { Check } from "~/icons/Check";
import { getCountryCode } from "~/server/geolocation";
import { getPricesForCountry } from "~/server/stripe";

import type { ButtonProps } from "./Button";
import { Button } from "./Button";
import { Paper } from "./Paper";
import { Skeleton } from "./Skeleton";
import { Typography } from "./Typography";
import { classNames } from "./utils";

const retrievePrices$ = server$(() => {
  return getPricesForCountry(getCountryCode(server$.request));
});

// interface GetCheckoutUrlOptions {
//   unit: "small" | "large";
//   cancelUrl?: string;
//   successUrl?: string;
// }

// const getCheckoutUrl = server$(async (options: GetCheckoutUrlOptions) => {
//   const request = server$.request;
//   const session = await getSession(request);
//   if (!session || !session.user) {
//     return "/login";
//   }
//   const countryCode = request.headers.get("x-vercel-ip-country") ?? "US";
//   const sessionUrl = await createCheckoutSession({
//     countryCode,
//     price: options.unit,
//     successUrl: options.successUrl
//       ? `${getHost(request.url)}${options.successUrl}`
//       : `${getHost(request.url)}/checkout?session_id={CHECKOUT_SESSION_ID}`,
//     cancelUrl: options.cancelUrl
//       ? `${getHost(request.url)}${options.cancelUrl}`
//       : `${getHost(request.url)}/profile`,
//     user: session.user,
//   });
//   if (!sessionUrl) {
//     throw new Error("Server error");
//   }
//   return sessionUrl;
// });

function getCurrencySymbol(currency: string) {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency.toUpperCase(),
  });
  const symbol = currencyFormatter
    .formatToParts(1)
    .find((x) => x.type === "currency");
  if (!symbol) {
    throw new Error("No currency symbol");
  }
  return symbol.value;
}

function formatUnitAmount(amount: number) {
  const numberFormatter = new Intl.NumberFormat("en-US", { style: "decimal" });
  return numberFormatter.format(amount / 100);
}

function formatPrice(unitAmount: number, currency: string) {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency.toUpperCase(),
  });
  return currencyFormatter.format(unitAmount / 100);
}

function Features() {
  return (
    <ul class="w-full mt-4 px-4 space-y-3">
      <li class="flex items-center">
        <Check class="text-green-600 w-5 h-5 mr-2" /> Edit, revise and download
        your cover letter
      </li>
      <li class="flex items-center">
        <Check class="text-green-600 w-5 h-5 mr-2" /> Quick AI edits: Shorten
        and rephrase with a single click
      </li>
      <li class="flex items-center">
        <Check class="text-green-600 w-5 h-5 mr-2" /> 10 regenerations per cover
        letter
      </li>
      <li class="flex items-center">
        <Check class="text-green-600 w-5 h-5 mr-2" /> Even better output with
        the latest, improved AI model
      </li>
    </ul>
  );
}

interface UnitAmountProps {
  unitAmount: number;
  currency: string;
}

function UnitAmount(props: UnitAmountProps) {
  const currencySymbol = () => getCurrencySymbol(props.currency);
  const priceNoSymbol = () => formatUnitAmount(props.unitAmount);
  return (
    <Typography variant="hero" class="text-center">
      <span class="text-xl mr-1 align-top">{currencySymbol()}</span>
      {priceNoSymbol()}
    </Typography>
  );
}

interface PurchaseButtonProps {
  unitQuantity: number;
  unitAmount: number;
  currency: string;
  isRedirecting?: boolean;
}

function PurchaseButton(props: ButtonProps & PurchaseButtonProps) {
  const [, buttonProps] = splitProps(props, [
    "unitAmount",
    "unitQuantity",
    "currency",
    "isRedirecting",
  ]);
  const price = () => formatPrice(props.unitAmount, props.currency);
  return (
    <Button
      {...buttonProps}
      class={classNames(buttonProps.class, "mt-6 w-full")}
    >
      <Show
        when={!props.isRedirecting}
        fallback={<Button.Loading>Redirecting...</Button.Loading>}
      >
        Buy {props.unitQuantity} for {price()}
      </Show>
    </Button>
  );
}

interface UnitQuantityProps {
  unitQuantity: number;
}

function UnitQuantity(props: UnitQuantityProps) {
  return (
    <Typography
      variant="body-lg"
      class="font-semibold text-gray-500 dark:text-gray-400 text-center"
    >
      {props.unitQuantity} cover letters
    </Typography>
  );
}

function Unit(props: ParentProps) {
  return (
    <Paper class="relative basis-full grow !px-10 !py-12">
      {props.children}
    </Paper>
  );
}

interface PricesProps {
  cancelUrl?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function Prices(_props: PricesProps) {
  const [pricingInfo] = createResource(() => retrievePrices$());
  return (
    <Suspense
      fallback={
        <div class="space-y-10 sm:space-y-0 sm:flex sm:space-x-10 md:space-x-20">
          <Unit>
            <UnitQuantity unitQuantity={5} />
            <Skeleton class="w-full">
              <Skeleton.Line class="!h-10" />
              <Features />
              <Skeleton.Rect class="w-full h-10 mt-6" />
            </Skeleton>
          </Unit>
          <Unit>
            <UnitQuantity unitQuantity={10} />
            <Skeleton class="w-full">
              <Skeleton.Line class="!h-10" />
              <Features />
              <Skeleton.Rect class="w-full h-10 mt-6" />
            </Skeleton>
          </Unit>
        </div>
      }
    >
      <Show when={pricingInfo()} keyed>
        {(pricingInfo) => (
          <div class="space-y-10 sm:space-y-0 sm:flex sm:space-x-10 md:space-x-20">
            <Unit>
              <UnitQuantity unitQuantity={5} />
              <UnitAmount
                currency={pricingInfo.small.currency}
                unitAmount={pricingInfo.small.unitAmount ?? 0}
              />
              <Features />
              <PurchaseButton
                variant="outlined"
                color="white"
                currency={pricingInfo.small.currency}
                unitAmount={pricingInfo.small.unitAmount ?? 0}
                unitQuantity={5}
                disabled={true}
              />
              <Typography
                variant="body-sm"
                class="text-center mt-4 text-red-900"
              >
                Unfortunately, we are not allowing any additional purchases.
              </Typography>
            </Unit>
            <Unit>
              <UnitQuantity unitQuantity={10} />
              <UnitAmount
                currency={pricingInfo.large.currency}
                unitAmount={pricingInfo.large.unitAmount ?? 0}
              />
              <Features />
              <PurchaseButton
                currency={pricingInfo.large.currency}
                unitAmount={pricingInfo.large.unitAmount ?? 0}
                unitQuantity={10}
                disabled={true}
              />
              <Typography
                variant="body-sm"
                class="text-center mt-4 text-red-900"
              >
                Unfortunately, we are not allowing any additional purchases.
              </Typography>
              <Typography
                variant="body-sm"
                class="absolute top-6 left-0 right-0 text-center font-semibold text-indigo-600 dark:text-indigo-400"
              >
                Save 20%
              </Typography>
            </Unit>
          </div>
        )}
      </Show>
    </Suspense>
  );
}
