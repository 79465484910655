import { createVisibilityObserver } from "@solid-primitives/intersection-observer";
import type { JSX } from "solid-js";
import { createEffect } from "solid-js";
import { A, ErrorBoundary } from "solid-start";

import { Accordion } from "~/components/Accordion";
import { Button } from "~/components/Button";
import { Feature } from "~/components/Feature";
import { Layout } from "~/components/Layout";
import { Paper } from "~/components/Paper";
import { Prices } from "~/components/Prices";
import { SiteCanonical } from "~/components/SiteCanonical";
import { SiteDescription } from "~/components/SiteDescription";
import { SiteTitle } from "~/components/SiteTitle";
import { SocialProof } from "~/components/SocialProof";
import { Testimonial } from "~/components/Testimonial";
import { Typography } from "~/components/Typography";
import { AdjustmentsVertical } from "~/icons/AdjustmentsVertical";
import { Briefcase } from "~/icons/Briefcase";
import { Clock } from "~/icons/Clock";
import { UserCircle } from "~/icons/UserCircle";
import { examplesSequence, getRandomExample } from "~/server/examples";
import { createTypeWriter } from "~/utils/typeWriter";

function AutoplayVideo(props: JSX.IntrinsicElements["video"]) {
  let ref: HTMLVideoElement | undefined;
  const useVisibilityObserver = createVisibilityObserver({ threshold: 0.8 });
  const visible = useVisibilityObserver(() => ref);
  createEffect(() => {
    if (visible()) {
      ref?.play().catch(() => {
        //
      });
    }
  });
  return <video {...props} ref={ref} preload="metadata" playsinline />;
}

function HeroLink() {
  return (
    <div class="flex mb-6">
      <A
        href="/blog/do-i-really-need-a-cover-letter-in-2023"
        class="ring-1 ring-gray-900/10 hover:ring-gray-900/20 dark:ring-gray-200/10 dark:hover:ring-gray-200/20 rounded-full inline-flex items-center px-4 py-1 transition-all group"
      >
        <Typography
          variant="body-xs"
          class="font-semibold text-indigo-600 dark:text-indigo-300"
        >
          Blog
        </Typography>
        <Typography
          variant="body-xs"
          class="font-semibold ml-2 dark:text-gray-200"
        >
          Why a cover letter is still important in 2023
        </Typography>
        <span class="ml-2 text-gray-600 dark:text-gray-400 inline-block transition-transform group-hover:translate-x-1 group-disabled:translate-x-0">
          &rarr;
        </span>
      </A>
    </div>
  );
}

function Hero() {
  const content = createTypeWriter(examplesSequence);
  return (
    <section id="hero" class="relative">
      <div class="mx-auto max-w-7xl pt-2 sm:pt-10 pb-20 sm:pb-36 flex flex-col lg:flex-row gap-8 sm:gap-20">
        <div class="basis-1/2">
          <HeroLink />
          <Typography as="h1" variant="hero-lg" class="dark:text-gray-200">
            Create Impactful Cover Letters with AI
          </Typography>
          <Typography
            variant="body-lg"
            class="text-gray-600 dark:text-gray-400 mt-6"
          >
            Use the latest AI technology, real-life examples and professional
            templates to make a lasting impression on employers in your job
            search.
          </Typography>
          <div class="flex flex-col sm:flex-row items-start mt-6 space-y-3 sm:space-y-0 sm:space-x-6">
            <Button as={A} href="/letter/new">
              Try it for free now
              <Button.Arrow class="text-indigo-200" />
            </Button>
            <Button
              as={A}
              href={getRandomExample()}
              variant="outlined"
              color="white"
            >
              See some examples
              <Button.Arrow class="text-gray-500" />
            </Button>
          </div>
          <SocialProof class="mt-6" />
        </div>
        <div class="basis-1/2 relative">
          <Paper class="aspect-[1/1.4142] max-w-full min-h-[65vh] h-[500px] overflow-auto mx-auto !px-8 !py-16 lg:absolute lg:top-1/2 lg:left-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 lg:animate-float">
            <pre class="whitespace-pre-wrap font-sans text-sm select-none">
              {`Dear Hiring Manager,\n\n`}
              {content()}
            </pre>
          </Paper>
        </div>
      </div>
      <div class="text-center w-full absolute left-0 bottom-0 hidden lg:block">
        <a href="#features" class="text-gray-500 dark:text-gray-400 text-xs">
          Read more
          <span
            class="block motion-safe:animate-bounce mt-1 text-gray-400"
            aria-hidden="true"
          >
            &darr;
          </span>
        </a>
      </div>
    </section>
  );
}

function Features() {
  return (
    <section
      id="features"
      class="bg-white dark:bg-slate-900 py-12 sm:py-16 lg:py-20"
    >
      <div class="mx-auto max-w-7xl">
        <div class="mx-auto max-w-3xl sm:text-center">
          <Typography
            variant="body-lg"
            class="font-semibold text-indigo-600 dark:text-indigo-400"
          >
            Personalized time-savers
          </Typography>
          <Typography
            as="h2"
            variant="hero"
            class="text-gray-900 dark:text-gray-200 mt-2"
          >
            Get ahead with AI-generated cover letters
          </Typography>
          <Typography
            variant="body-lg"
            class="mt-6 text-gray-600 dark:text-gray-400"
          >
            Our AI-generated cover letters offer a range of features to help you
            stand out from the competition and increase your chances of getting
            hired.
          </Typography>
        </div>
        <div class="mt-20 max-w-lg sm:mx-auto md:max-w-none">
          <div class="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
            <Feature>
              <Feature.Icon>
                <UserCircle />
              </Feature.Icon>
              <Feature.Content>
                <Feature.Headline>Personalized</Feature.Headline>
                <Feature.Text>
                  Our advanced AI technology creates unique cover letters for
                  each job application, tailoring the content to match the
                  requirements of the specific role and company, as well as your
                  own profile and experience.
                </Feature.Text>
              </Feature.Content>
            </Feature>
            <Feature>
              <Feature.Icon class="!bg-red-500">
                <Briefcase />
              </Feature.Icon>
              <Feature.Content>
                <Feature.Headline>Professional</Feature.Headline>
                <Feature.Text>
                  Our AI-generated cover letters are written in a clear and
                  concise style, using language and formatting that is
                  appropriate for a professional job application.
                </Feature.Text>
              </Feature.Content>
            </Feature>
            <Feature>
              <Feature.Icon class="!bg-green-500">
                <AdjustmentsVertical />
              </Feature.Icon>
              <Feature.Content>
                <Feature.Headline>Customizable</Feature.Headline>
                <Feature.Text>
                  You can easily customize your AI-generated cover letter to add
                  your own personal touch and make it truly unique.
                </Feature.Text>
              </Feature.Content>
            </Feature>
            <Feature>
              <Feature.Icon class="!bg-yellow-500">
                <Clock />
              </Feature.Icon>
              <Feature.Content>
                <Feature.Headline>Time-saving</Feature.Headline>
                <Feature.Text>
                  With our AI-generated cover letters, you can quickly and
                  easily create high-quality cover letters for multiple job
                  applications, saving you time and effort.
                </Feature.Text>
              </Feature.Content>
            </Feature>
          </div>
        </div>
      </div>
    </section>
  );
}

function HowTo() {
  return (
    <section
      id="how-to"
      class="bg-white dark:bg-slate-900 py-12 sm:py-16 lg:py-20"
    >
      <div class="mx-auto max-w-7xl">
        <div class="mx-auto max-w-3xl sm:text-center">
          <Typography
            variant="body-lg"
            class="text-indigo-600 dark:text-indigo-400 font-semibold"
          >
            How it works
          </Typography>
          <Typography as="h2" variant="hero" class="mt-2 dark:text-gray-200">
            Fast and easy
          </Typography>
          <Typography
            variant="body-lg"
            class="text-gray-600 dark:text-gray-400 mt-6"
          >
            Generate a professional cover letter in minutes! Our simple
            three-step process makes it fast and easy to tailor your cover
            letter to any job you're applying for.
          </Typography>
        </div>
        <div class="mt-20 md:flex items-center justify-between md:space-x-16">
          <div class="max-w-lg sm:max-w-xl space-y-10 basis-full">
            <Feature>
              <Feature.Icon class="!bg-indigo-700">1</Feature.Icon>
              <Feature.Content>
                <Feature.Headline>Upload your resume</Feature.Headline>
                <Feature.Text>
                  Your resume is an important source of information for our AI,
                  as it provides details about your education, experience,
                  skills, and achievements.
                </Feature.Text>
              </Feature.Content>
            </Feature>
            <Feature>
              <Feature.Icon class="!bg-indigo-600">2</Feature.Icon>
              <Feature.Content>
                <Feature.Headline>
                  Paste in the job description
                </Feature.Headline>
                <Feature.Text>
                  The job description provides the AI with information about the
                  specific role and company you are applying to, including the
                  requirements, duties, and responsibilities of the job.
                </Feature.Text>
              </Feature.Content>
            </Feature>
            <Feature>
              <Feature.Icon>3</Feature.Icon>
              <Feature.Content>
                <Feature.Headline>
                  Generate your AI-generated cover letter
                </Feature.Headline>
                <Feature.Text>
                  Once you've uploaded your resume and pasted in the job
                  description, the AI will generate your custom-made cover
                  letter, and within a few moments, it will be ready for you to
                  review, make any edits you want and download.
                </Feature.Text>
              </Feature.Content>
            </Feature>
          </div>
          <div class="basis-full mt-10 md:mt-0">
            <div class="w-full my-auto rounded-xl overflow-hidden shadow-lg bg-slate-800">
              <div class="flex items-center h-12 px-4 space-x-1.5 border-b border-slate-800">
                <div class="w-3 h-3 border-2 rounded-full border-red-500"></div>
                <div class="w-3 h-3 border-2 rounded-full border-yellow-500"></div>
                <div class="w-3 h-3 border-2 rounded-full border-green-500"></div>
              </div>
              <AutoplayVideo
                class="w-full rounded-b-xl border border-slate-800"
                poster="/walkthrough.jpg"
                loop
                muted
                width="574"
                height="409"
              >
                <source src="/walkthrough.webm" type="video/webm" />
              </AutoplayVideo>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Testimonials() {
  return (
    <section
      id="testimonials"
      class="bg-white dark:bg-slate-900 py-12 sm:py-16 lg:py-20"
    >
      <div class="mx-auto max-w-7xl">
        <div class="bg-gradient-to-br from-indigo-800 to-indigo-900 rounded-3xl px-12 py-16 relative shadow-xl">
          <div
            role="presentation"
            class="absolute top-1/2 left-0 right-0 border-t-2 md:border-t-0 md:right-auto md:right-auto md:top-0 md:bottom-0 md:left-1/2 md:border-l-2 border-indigo-900"
          ></div>
          <div class="space-y-20 md:space-y-0 md:flex md:space-x-16">
            <Testimonial class="basis-full">
              <Testimonial.Quote>
                I struggled to get responses to my job applications. That's why
                I tried the AI-generated cover letter service. I was skeptical,
                but the process was simple and fast. The AI-generated cover
                letter was tailored to the job I applied for and highlighted my
                skills.
              </Testimonial.Quote>
              <Testimonial.Author>
                <Testimonial.Image
                  src="/testimonials/image_small.jpeg"
                  alt="Profile picture of Michael K."
                  loading="lazy"
                />
                <Testimonial.AuthorDetails>
                  <Typography class="text-white">Michael K.</Typography>
                  <Typography class="text-indigo-200">
                    Software Engineer
                  </Typography>
                </Testimonial.AuthorDetails>
              </Testimonial.Author>
            </Testimonial>
            <Testimonial class="basis-full">
              <Testimonial.Quote>
                It was easy to use and the results were impressive. The
                AI-generated cover letter was tailored to the job I applied for
                and showcased my skills. I got an interview and landed the job.
                I recommend this service to anyone looking to improve their
                chances of getting hired.
              </Testimonial.Quote>
              <Testimonial.Author>
                <Testimonial.Image
                  src="/testimonials/image-5_small.jpeg"
                  alt="Profile picture of Samantha J."
                  loading="lazy"
                />
                <Testimonial.AuthorDetails>
                  <Typography class="text-white">Samantha J.</Typography>
                  <Typography class="text-indigo-200">Accountant</Typography>
                </Testimonial.AuthorDetails>
              </Testimonial.Author>
            </Testimonial>
          </div>
        </div>
      </div>
    </section>
  );
}

export function Pricing() {
  return (
    <section
      id="pricing"
      class="bg-white dark:bg-slate-900 py-12 sm:py-16 lg:py-20"
    >
      <ErrorBoundary fallback={() => <div />}>
        <div class="mx-auto max-w-3xl sm:text-center">
          <Typography
            variant="body-lg"
            class="text-indigo-600 dark:text-indigo-400 font-semibold"
          >
            Pricing
          </Typography>
          <Typography as="h2" variant="hero" class="dark:text-gray-200 mt-2">
            Professional, custom-made cover letters for over 10x less than a
            writing service
          </Typography>
          <Typography
            variant="body-lg"
            class="text-gray-600 dark:text-gray-400 mt-6"
          >
            Get high-quality, professional cover letters for your next job
            application without breaking the bank. Our pricing is simple and
            straightforward with no hidden fees or subscriptions. And for
            additional ease of mind we offer a{" "}
            <A href="/letter/new" class="text-indigo-600 dark:text-indigo-400">
              free trial
            </A>{" "}
            for your first cover letter.
          </Typography>
        </div>
        <div class="mx-auto max-w-7xl mt-12">
          <Prices />
        </div>
        <SocialProof class="mx-auto max-w-lg mt-16 flex flex-col text-center" />
      </ErrorBoundary>
    </section>
  );
}

function FAQ() {
  return (
    <section
      id="cta"
      class="bg-white dark:bg-slate-900 py-12 sm:py-16 lg:py-20"
    >
      <div class="mx-auto max-w-7xl sm:flex">
        <div class="sm:basis-1/3">
          <Typography variant="hero" class="dark:text-gray-200">
            Frequently Asked Questions
          </Typography>
          <Typography
            variant="body-lg"
            class="mt-4 text-gray-600 dark:text-gray-400"
          >
            Answers to common questions about our AI generated cover letters. If
            you have any further questions or requests, feel free to{" "}
            <A href="/contact" class="text-indigo-600 dark:text-indigo-400">
              contact us
            </A>
            .
          </Typography>
        </div>
        <div class="mt-6 sm:mt-0 sm:basis-2/4 sm:ml-auto">
          <Accordion defaultValue="" toggleable>
            <Accordion.Item value="faq-7">
              <Accordion.Header>
                <Accordion.Button>
                  How is this different from ChatGPT?
                </Accordion.Button>
              </Accordion.Header>
              <Accordion.Panel>
                <Typography>
                  coverletter.app is a service tailored specifically to the
                  generation of cover letters.
                  <br />
                  <A
                    href="/compare/chatgpt"
                    class="text-indigo-600 dark:text-indigo-400"
                  >
                    Find out more
                  </A>
                </Typography>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="faq-0">
              <Accordion.Header>
                <Accordion.Button>
                  In what format will I receive the cover letter?
                </Accordion.Button>
              </Accordion.Header>
              <Accordion.Panel>
                <Typography>
                  You are able to download your cover letter as a PDF.
                </Typography>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="faq-1">
              <Accordion.Header>
                <Accordion.Button>
                  What formats do you support for my resume/CV?
                </Accordion.Button>
              </Accordion.Header>
              <Accordion.Panel>
                <Typography>
                  Right now, we only support PDFs. So please upload your resume
                  as a PDF file.
                </Typography>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="faq-2">
              <Accordion.Header>
                <Accordion.Button>What is the free trial?</Accordion.Button>
              </Accordion.Header>
              <Accordion.Panel>
                <Typography>
                  You can test our AI generated cover letters for free after
                  signing up - no credit card required. You'll be able to create
                  1 cover letter. Just upload your resume and enter the job
                  description. You won't be able to download or edit the cover
                  letter, but you can convince yourself of its quality.
                </Typography>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="faq-3">
              <Accordion.Header>
                <Accordion.Button>Where is my data stored?</Accordion.Button>
              </Accordion.Header>
              <Accordion.Panel>
                <Typography>
                  Your data is stored securely on servers in the United States,
                  by vetted, highly secure, third party partners of us in
                  accordance with our terms and conditions and privacy
                  statement.
                </Typography>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="faq-4">
              <Accordion.Header>
                <Accordion.Button>Can I get a refund?</Accordion.Button>
              </Accordion.Header>
              <Accordion.Panel>
                <Typography>
                  Yes, we can refund purchases made within the first 14 days,
                  provided you haven't created any cover letters or you are
                  having issues using the service. Please refer to our{" "}
                  <A href="/terms" class="text-indigo-600 dark:text-indigo-400">
                    terms and conditions
                  </A>{" "}
                  for details.
                </Typography>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="faq-5">
              <Accordion.Header>
                <Accordion.Button>Is payment secure?</Accordion.Button>
              </Accordion.Header>
              <Accordion.Panel>
                <Typography>
                  Yes, we use Stripe for payment. We do not store any of your
                  credit card information.
                </Typography>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="faq-6">
              <Accordion.Header>
                <Accordion.Button>Can I get an invoice?</Accordion.Button>
              </Accordion.Header>
              <Accordion.Panel>
                <Typography>
                  Yes, you can get an invoice for your purchase. After you have
                  logged into your account, click on your profile picture and
                  select "Invoices".
                </Typography>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </section>
  );
}

function CTA() {
  return (
    <section id="cta" class="bg-white dark:bg-slate-900">
      <div class="mx-auto max-w-7xl">
        <div class="bg-gradient-to-br from-indigo-700 to-indigo-800 rounded-3xl px-12 py-16 relative shadow-xl text-white text-center">
          <Typography variant="hero">
            Create high-quality cover letters in seconds
          </Typography>
          <Typography variant="body-lg" class="mt-4 text-indigo-100">
            Sign up now and get personalized, professional, and customizable
            AI-generated cover letters!
          </Typography>
          <Button as={A} href="/letter/new" color="white" class="mt-6">
            Get started for free now <Button.Arrow />
          </Button>
        </div>
      </div>
    </section>
  );
}

export default function Home() {
  return (
    <Layout>
      <SiteCanonical href="" />
      <SiteTitle>AI Cover Letters, Examples & Templates</SiteTitle>
      <SiteDescription>
        Unleash coverletter.app's AI potential to craft impactful and
        personalized cover letters. Utilize real examples and professional
        templates to stand out in job searches and impress employers.
      </SiteDescription>
      <Layout.Header>
        <Button
          as={A}
          href="/letter/new"
          variant="outlined"
          color="white"
          class="hidden sm:inline-block"
        >
          Try it for free now <Button.Arrow class="text-gray-500" />
        </Button>
      </Layout.Header>
      <Layout.Main>
        <Hero />
        <Features />
        <Testimonials />
        <HowTo />
        <Pricing />
        <FAQ />
        <CTA />
      </Layout.Main>
    </Layout>
  );
}
