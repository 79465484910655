import type {
  AccordionButtonProps,
  AccordionHeaderProps,
  AccordionItemProps,
  AccordionPanelProps,
  AccordionProps,
} from "solid-headless";
import {
  Accordion as HeadlessAccordion,
  AccordionButton as HeadlessAccordionButton,
  AccordionHeader as HeadlessAccordionHeader,
  AccordionItem as HeadlessAccordionItem,
  AccordionPanel as HeadlessAccordionPanel,
} from "solid-headless";

import { MinusSmall } from "~/icons/MinusSmall";
import { PlusSmall } from "~/icons/PlusSmall";

import type { ValidConstructor } from "./utils";
import { classNames } from "./utils";

export function Accordion<V, T extends ValidConstructor>(
  props: AccordionProps<V, T>
) {
  return (
    <HeadlessAccordion
      {...props}
      class={classNames(
        props.class,
        "divide-y divide-gray-200 dark:divide-slate-800"
      )}
    />
  );
}

Accordion.Button = function AccordionButton<T extends ValidConstructor>(
  props: AccordionButtonProps<T>
) {
  return (
    <HeadlessAccordionButton
      {...props}
      class={classNames(
        props.class,
        "w-full py-6 font-semibold flex items-center justify-between text-left dark:text-gray-200"
      )}
    >
      {(options) => (
        <>
          {props.children}{" "}
          {options.isSelected() ? (
            <MinusSmall class="w-6 h-6 ml-4" aria-hidden />
          ) : (
            <PlusSmall class="w-6 h-6 ml-4" aria-hidden />
          )}
        </>
      )}
    </HeadlessAccordionButton>
  );
};

Accordion.Header = function AccordionHeader<T extends ValidConstructor>(
  props: AccordionHeaderProps<T>
) {
  return <HeadlessAccordionHeader {...props} />;
};

Accordion.Item = function AccordionItem<V, T extends ValidConstructor>(
  props: AccordionItemProps<V, T>
) {
  return <HeadlessAccordionItem {...props} />;
};

Accordion.Panel = function AccordionPanel<T extends ValidConstructor>(
  props: AccordionPanelProps<T>
) {
  return (
    <HeadlessAccordionPanel
      {...props}
      class={classNames(props.class, "-mt-4 mb-6 dark:text-gray-400")}
    />
  );
};
