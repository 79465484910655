import type { JSX, ParentProps } from "solid-js";

import { Typography } from "./Typography";
import { classNames } from "./utils";

export function Testimonial(props: ParentProps<{ class?: string }>) {
  return (
    <div class={classNames("relative", props.class)}>
      <div
        role="presentation"
        class="absolute -top-6 -left-4 text-8xl leading-none font-semibold text-indigo-600 font-serif"
      >
        &#8220;
      </div>
      <div class="relative">{props.children}</div>
    </div>
  );
}

Testimonial.Quote = function TestimonialQuote(props: ParentProps) {
  return (
    <Typography variant="body-lg" class="text-white">
      {props.children}
    </Typography>
  );
};

Testimonial.Author = function TestimonialAuthor(props: ParentProps) {
  return <div class="flex space-x-4 mt-4">{props.children}</div>;
};

Testimonial.Image = function TestimonialImage(
  props: JSX.IntrinsicElements["img"]
) {
  return (
    <img class="w-12 h-12 border-white border-2 rounded-full" {...props} />
  );
};

Testimonial.AuthorDetails = function TestimonialAuthorDetails(
  props: ParentProps
) {
  return <div>{props.children}</div>;
};
