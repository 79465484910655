import type { JSX } from "solid-js";

import { StarFilled } from "~/icons/StarFilled";

import { Typography } from "./Typography";

export function SocialProof(props: JSX.IntrinsicElements["div"]) {
  return (
    <div {...props} class={props.class}>
      <div class="flex mb-2 mx-auto items-center" role="presentation">
        <img
          src="/testimonials/image_small.jpeg"
          class="w-10 h-10 rounded-full"
          alt="Picture of a customer"
          loading="lazy"
        />
        <img
          src="/testimonials/image-2_small.jpeg"
          class="w-10 h-10 rounded-full -ml-4"
          alt="Picture of a customer"
          loading="lazy"
        />
        <img
          src="/testimonials/image-3_small.jpeg"
          class="w-10 h-10 rounded-full -ml-4"
          alt="Picture of a customer"
          loading="lazy"
        />
        <img
          src="/testimonials/image-4_small.jpeg"
          class="w-10 h-10 rounded-full -ml-4"
          alt="Picture of a customer"
          loading="lazy"
        />
        <img
          src="/testimonials/image-5_small.jpeg"
          class="w-10 h-10 rounded-full -ml-4"
          alt="Picture of a customer"
          loading="lazy"
        />
        <div class="text-yellow-400 flex ml-3">
          <StarFilled class="w-5 h-5" aria-hidden />
          <StarFilled class="w-5 h-5" aria-hidden />
          <StarFilled class="w-5 h-5" aria-hidden />
          <StarFilled class="w-5 h-5" aria-hidden />
          <StarFilled class="w-5 h-5" aria-hidden />
        </div>
      </div>
      <Typography variant="body-sm" class="text-gray-600 dark:text-gray-400">
        Thousands of people have created their professional cover letter with
        our tool. Get yours today!
      </Typography>
    </div>
  );
}
