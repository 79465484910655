import type { ParentProps } from "solid-js";

import { classNames } from "./utils";

export function Feature(props: ParentProps) {
  return (
    <div class="relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row">
      {props.children}
    </div>
  );
}

interface FeatureIconProps {
  class?: string;
}

Feature.Icon = function FeatureIcon(props: ParentProps<FeatureIconProps>) {
  return (
    <div
      class={classNames(
        props.class,
        "flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-500 text-white sm:shrink-0 text-xl leading-none"
      )}
    >
      {props.children}
    </div>
  );
};

Feature.Content = function FeatureContent(props: ParentProps) {
  return <div class="sm:min-w-0 sm:flex-1">{props.children}</div>;
};

Feature.Headline = function FeatureHeadline(props: ParentProps) {
  return (
    <p class="text-lg font-semibold leading-8 text-gray-900 dark:text-gray-300">
      {props.children}
    </p>
  );
};

Feature.Text = function FeatureText(props: ParentProps) {
  return (
    <p class="mt-2 text-base leading-7 text-gray-600 dark:text-gray-400">
      {props.children}
    </p>
  );
};
