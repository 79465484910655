import { createSignal, onCleanup, onMount } from "solid-js";

function wait(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

function removeLastWord(text: string) {
  const words = text.split(" ");
  return words.slice(0, words.length - 1).join(" ");
}

export function createTypeWriter(texts: string[], speedMs = 10, waitMs = 1000) {
  const [rendered, setRendered] = createSignal("");
  let stop = false;

  async function renderText(text: string) {
    let result = "";
    for (const word of text.split(" ")) {
      if (stop) {
        return;
      }
      if (!result) {
        result = word;
      } else {
        result += " " + word;
      }
      setRendered(result);
      await wait(speedMs);
    }
    await wait(waitMs);
    while (result && !stop) {
      result = removeLastWord(result);
      setRendered(result);
      await wait(speedMs);
    }
  }

  async function renderTexts(texts: string[]) {
    let i = 0;
    // eslint-disable-next-line no-constant-condition
    while (!stop) {
      const text = texts[i];
      await renderText(text);
      i = (i + 1) % texts.length;
    }
  }

  onMount(() => {
    renderTexts(texts);
  });

  onCleanup(() => {
    stop = true;
  });

  return rendered;
}
